export const projects = [
    "ScaleUP",
    "OS_MoQPlayer",
    "OS_VASTtoSGAI",
    "Wowza - WSE",
    "Wowza - SDK",
    "Wowza - View team",
    "Dolby-RocketBears",
    "Dolby - MOE",
    "Dolby - Musqueteers",
    "Dolby - DevOps/SRE",
    "Dolby_RASP",
    "OpenPath - Quantastic4",
    "AMD - Evaluation App",
    "Sky - Galaxy Gang",
    "Sky - Daft Ducks",
    "Blockcast",
    "FAST Channel Scheduler",
    "COMCAST_ContentSteeringCMCD",
    "Ajnunna - Strava-encoding-infra",
    "MUX_OS&QA",
    "MUX_AdsPlayer",
    "NBC_MOE&CAE",
    "Bitmovin_VideoAnalytics",
    "EZDRM_C2PAKMS",
    "tracking-tool",
    "Investigación",
    "Pre venta",
    "Planificación",
    "Capacitación",
    "No trabajé en ningún proyecto",
    "No vine por estudios",
    "No vine por vacaciones",
    "No vine por enfermedad",
    "No vine por otro motivo (feriado/día libre/imprevistos/etc...)"
];

export const qualabbers = [
    "Nico",
    "Javier",
    "Juan Manuel",
    "Gonza",
    "Ismael Pintos",
    "Maximiliano Pollinger",
    "Renzo",
    "Juan Manuel Tomás",
    "Manuel Calleriza",
    "Fabian Cancela",
    "Nicolás Ramos",
    "Agustín dos Santos",
    "Tomás Arrivillaga",
    "Stefano Graziani",
    "Sebastián Piquerez",
    "Facundo Mendizábal",
    "Nicolás Pérez",
    "Tatiana Perera",
    "Sergio Tassara",
    "Ronald Urbina",
    "Joaquín Bartaburu",
    "Santiago Otero",
    "Constanza Dibueno",
    "Agustín Bologna",
    "Mateo Trujillo",
    "Camilo Borba",
    "Yuliana Gomez",
    "Gastón Bogarín",
    "Santiago Souto",
    "Diego Helal",
    "Gimena Alamón",
    "Martina Severo",
    "Jonathan Armesto",
    "Juan Vitali",
    "Lucas Pintos",
    "Juan Diego Irazoqui",
    "Martin Hargain",
    "Santiago Puppo",
    "Juan Capua",
    "Lautaro Fernández",
    "Ignacio Olivan",
    "Alfredo Eyheralde",
    "Franco Daneri",
    "Lukas Bergengruen",
    "Klaus Gugelmeier",
    "Luis Serra",
    "Santiago Rocandio",
    "Ivan Monjardin",
    "David Pigna",
    "Marcos de Oliveira",
    "Romina Sosa",
    "Manuel Barrabino",
    "Nicolas Bertillod",
    "Victoria Tournier",
    "Julieta Percivale",
    "Fabrizio Persichetti",
    "Nicolas Mayora"
];
